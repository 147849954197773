<template>
  <base-page>
    <div slot="left-column" class="back-button q-mx-sm ">
      <q-btn icon="arrow_back" :color="color" class="full-height full-width" size="md" outline @click="$router.go(-1)">
        {{$t('global.back')}}
      </q-btn>
    </div>

    <router-view />
  </base-page>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ProcessesPage',
  components: { },

  meta () {
    return {
      titleTemplate: title => `${title} - Processing | AGORA`
    }
  },
  computed: {
    ...mapGetters({
      color: 'pages/getColor'
    })
  },
  mounted () {
    // this.$store.commit('pages/setBreadcrumbs', [
    //   { label: this.$t('global.home'), to: { name: 'home' }, icon: 'home' },
    //   {}
    // ])
  }
}
</script>

<style lang="stylus" scoped>
  .back-button
    height 65px
</style>
